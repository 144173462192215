<template>
  <div class="contentWrap en">
    <div v-for="(item,index) in dataInfo" :key="index">
      <m-food-en-bar :resData="item" @orderclick="orderclick"></m-food-en-bar>
    </div>
  </div>
</template>

<script type="text/ecmascript-6">
import FoodEnBar from "@/components/FoodEnBar";
import axios from "axios";
import config from "@/utils/config";

export default {
  components: {
    [FoodEnBar.name]: FoodEnBar
  },
  data() {
    return {
      dataInfo: new Array()
    };
  },
  mounted() {
    this.getData(config.foodXiCanUrl_en);
  },
  methods: {
    async getData(url) {
      try {
        let res = await axios.get(url);
        if (res.data.code == "0" && res.data.data instanceof Array) {
          if (res.data.data.length > 0) {
            let tempData = JSON.parse(JSON.stringify(res.data.data));
            this.dataInfo = tempData;
            // console.log("res.data.data:", this.dataInfo);
          }
        }
      } catch (e) {
        console.log("res err:", e);
      }
    },
    orderclick() {
      this.$emit("orderclick");
      // console.log('2')
    }
  }
};
</script>

<style scoped lang="scss" rel="stylesheet/scss">
@import "src/assets/styles/variables.scss";
.contentWrap {
  margin-top: 35px;
}
</style>
